const CustomConsts = {
    install(Vue, options) {
        const CONSTS = {
            DEFAULT_FORM_STATE: {
                gstList : [
                    {
                      "id": "0",
                      "name": "0"
                    },
                    {
                      "id": "1",
                      "name": "1"
                    },
                    {
                      "id": "3",
                      "name": "3"
                    },
                    {
                      "id": "5",
                      "name": "5"
                    },
                    {
                      "id": "6",
                      "name": "6"
                    },
                    {
                      "id": "12",
                      "name": "12"
                    },
                    {
                      "id": "13",
                      "name": "13"
                    },
                    {
                      "id": "14",
                      "name": "14"
                    },
                    {
                      "id": "18",
                      "name": "18"
                    },
                    {
                      "id": "28",
                      "name": "28"
                    }
                  ]
            }
        }
        Vue.prototype.$getConst = (key) => {
            return CONSTS[key]
        }
    }
};

export default CustomConsts;