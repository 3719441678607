import axios from "axios";
export default ({ requiresAuth = false } = {}) => {
  const options = {
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: { Authorization: "" },
  };

  if (requiresAuth) {
    options.headers.Authorization = "Bearer " + localStorage.getItem("token");
  }
  const instance = axios.create(options);

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance;
};
