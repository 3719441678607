<template>
  <v-layout align-center justify-center>
  <v-flex xs12 sm8 md6>
    <div>
      <v-card class="b_shadow">
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container fluid>
              <v-row wrap>
                <v-flex xs12 md8 class="pt-2">
                  <v-row class="ml-2 mr-2">
                    <v-text-field
                      v-model="current_user.firstName"
                      :rules="nameRules"
                      :counter="20"
                      label="First name"
                      required
                    ></v-text-field>
                  </v-row>

                  <v-row class="ml-2 mr-2">
                    <v-text-field
                      v-model="current_user.lastName"
                      :rules="nameRules"
                      :counter="20"
                      label="Last name"
                      required
                    ></v-text-field>
                  </v-row>

                  <v-row class="ml-2 mr-2">
                    <v-text-field
                      v-model="current_user.email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                  </v-row>

                  <v-row class="ml-2 mr-2">
                    <v-text-field
                      v-model="current_user.mobile"
                      type="number"
                      :rules="mobileRules"
                      label="Mobile Number"
                    ></v-text-field>
                  </v-row>
                  <v-row class="ml-2 mr-2">
                    <v-flex xs12 class="px-2">
                      <h4 class="font-weight-medium mb-2">Gender</h4>
                      <v-radio-group
                        v-model="current_user.gender"
                        :rules="requiredRules"
                        row
                        class="mt-0 pt-0"
                      >
                        <v-radio
                          label="Male"
                          value="MALE"
                          class="mt-0 pt-0"
                        ></v-radio>
                        <v-radio
                          label="Female"
                          value="FEMALE"
                          class="mt-0 pt-0"
                        ></v-radio>
                        <v-radio
                          label="Prefer Not to Say"
                          value="NA"
                          class="mt-0 pt-0"
                        ></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex xs12 class="my-3 px-2">
                      <h4 class="title font-weight-bold mb-4">
                        Contact Information
                      </h4>
                    </v-flex>
                    <v-flex xs12 class="pa-2">
                      <v-textarea
                        v-model="current_user.address"
                        :rules="requiredRules"
                        label="Address"
                        solo
                        prepend-inner-icon="mdi-map-marker"
                        :counter="150"
                        class="mt-0 pt-0"
                      ></v-textarea>
                    </v-flex>
                  </v-row>

                </v-flex>
                <!-- {{ avatar }} -->
                <v-flex xs12 md4 class="text-xs-center">
                  <v-container grid-list-xl offset-md="4">
                    <v-row class="mb-6" no-gutters>
                      <span><b>Click Below to change avatar</b></span>
                      <v-col md="6" offset-md="2" class="pt-2">
                        <image-input v-model="avatar">
                          <div slot="activator">
                            <v-avatar
                              size="150px"
                              v-ripple
                              v-if="!avatar"
                              class="grey lighten-3 mb-3"
                            >
                              <v-img
                                :src="this.userImage"
                                alt="Click to add avatar"
                              ></v-img>
                             
                            </v-avatar>
                            <v-avatar size="150px" v-ripple v-else class="mb-3">
                              <img :src="avatar.imageURL" alt="avatar" />
                            </v-avatar>
                          </div>
                        </image-input>
                        <v-slide-x-transition>
                        </v-slide-x-transition>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="4" offset-md="2">
                        <v-btn
                          type="button"
                          color="success"
                          small
                          @click="saveImage(avatar.imageURL)"
                          >
                          <v-progress-circular
                            v-if="loading"
                            indeterminate
                            :size="15"
                            :width="2"
                            color="white"
                          ></v-progress-circular>
                          <span v-if="loading">&nbsp;&nbsp;Loading...</span>
                          <span v-if="!loading">Save</span>
                        </v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-flex>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-flex>
</v-layout>
</template>
<script>
import ImageInput from "../components/ImageInput.vue";
import defaultAvatar from "../assets/avatar.png" 
export default {
  components: {
    ImageInput: ImageInput,
  },
  data: () => ({
    avatar: {
      imageURL: defaultAvatar,
    },
    loading : false,
    photo: "",
    userImage: "",
    current_user: {
      firstName : "",
      lastName   : "",
      email : "",
      mobile: "",
      gender : "",
      address : ""
    },
    valid: true,
    saving: false,
    saved: false,
    firstName: "",
    lastname: "",
    regmobileno: "",
    gender: "",
    formHasErrors: false,
    address: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 20 || "Name must be less than 10 characters",
    ],
    mobileRules: [
      (v) => !!v || "Mobile is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    requiredRules: [(v) => !!v || "This field required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
  }),

  created() {
    this.getUserById();
  },
  methods: {
    saveImage(photo) {
      if (this.avatar != null && !this.loading) {
        this.loading = true;
        this.photo = photo;
        let data = {
          id: this.current_user.id,
          image: this.photo,
        };
        this.$http.post("/appusers/update/image", data).then((response) => {
          this.loading = false;
        }).catch((err) => {
          this.loading = false;
        });
    }
    },
    getUserById() {
      var userId = localStorage.getItem("id");
      this.$http
        .get("/appusers/getbyid/" + userId)
        .then((response) => {
          this.current_user = { ...response.data};
        })
        .catch((err) => {});

      this.$http
        .get("appusers/profile/image/raw?userId=" + userId)
        .then((response) => {
          this.userImage = response.data;
          this.avatar.imageURL = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loading = false));
    },
    async updateUser() {},
    validate() {
      this.formHasErrors = true;
      this.$refs.form.validate();
    },
  },
};
</script>
